import { watch } from "vue";
import { fetchClient } from "@/common/fetchclient";
import { useUserStore } from "@/stores/user";

const client = fetchClient({ baseURL: "https://api.pipedrive.com/v1/" });

// resolves when pipedrive credentials are fully loaded and we are ready for requests
const ensureReady = function () {
  return new Promise((resolve) => {
    const userStore = useUserStore();
    if (userStore.pipedriveReady) return resolve();
    // resolve when the store tells us we are ready
    watch(
      () => userStore.pipedriveReady,
      (newVal) => {
        if (newVal) resolve();
      }
    );
  });
};

// merge default parameters into the ones requested for a specific request
function mergeParams(queryParams) {
  const userStore = useUserStore();
  const defaultParams = {
    api_token: userStore.pipedriveToken,
    start: 0, // default pagination to beginning
  };
  return Object.assign(defaultParams, queryParams);
}

export default {
  // methods
  get: async function (path, queryParams = {}) {
    await ensureReady();
    const params = mergeParams(queryParams);
    return client.get(path, { params }); // returns promise
  },
  post: async function (path, data = {}, queryParams = {}) {
    await ensureReady();
    const params = mergeParams(queryParams);
    return client.post(path, data, { params }); // returns promise
  },
  put: async function (path, data = {}, queryParams = {}) {
    await ensureReady();
    const params = mergeParams(queryParams);
    return client.put(path, data, { params }); // returns promise
  },
  delete: async function (path, queryParams = {}) {
    await ensureReady();
    const params = mergeParams(queryParams);
    return client.delete(path, { params }); // returns promise
  },
};
