<template>
  <section class="company-files section">
    <div>
      <!-- Company Files toolbar -->
      <div class="level toolbar">
        <div class="level-left">
          <div class="level-item">
            <slot name="title">
              <h2 class="is-size-3">Files</h2>
            </slot>
          </div>
        </div>

        <div v-if="!isConnected" class="level-center">
          <div class="level-item">
            <p class="is-size-7 has-text-right">
              <strong>No Google Drive folder connected. </strong><br />
              Option-click a folder below to connect it to {{ company.name }}.
            </p>
          </div>
        </div>

        <div v-else class="details level-right">
          <div class="level-item">
            <label class="is-small" for="expand-all">
              <input id="expand-all" v-model="expand" type="checkbox" class="is-small" />
              Expand Folders
            </label>
          </div>

          <div class="level-item">
            <div class="control has-icons-left">
              <input
                v-model="filterText"
                type="text"
                class="input is-small"
                placeholder="Find files..."
              />
              <span class="icon is-small is-left">
                <font-awesome-icon icon="search" />
              </span>
            </div>
          </div>

          <div class="level-item">
            <a class="button is-small" title="Reload files" @click="reloadFiles">
              <font-awesome-icon icon="sync" />
            </a>
          </div>

          <div class="level-item">
            <a class="button is-small" title="Disconnect GDrive folder" @click="disconnectFolder">
              <font-awesome-icon icon="unlink" />
            </a>
          </div>

          <div class="level-item">
            <GoogleDriveButton :href="googleDriveUrl" small />
          </div>
        </div>
      </div>

      <!-- Company File Listing -->
      <!-- With a folder connected, display it and load immediately -->

      <h1>{{ selectedFileURL }}</h1>
      <div v-if="selectedFileURL.length > 0" class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-content" style="width: 80%; height: 80%">
          <iframe :src="selectedFileURL" style="width: 100%; height: 100%"></iframe>
        </div>
        <button
          class="modal-close is-large"
          aria-label="close"
          @click="selectedFileURL = ''"
        ></button>
      </div>

      <FileList
        v-if="folderId"
        :id="folderId"
        ref="companyFileList"
        :is-root="true"
        :filter-text="filterText"
        :load-on-expand="true"
        :expand="expand"
        @clicked-file="openFile"
        @selected-file="showFile"
      />

      <!-- Without a connected folder start at the root folder, only show folders & lazily load -->
      <FileList
        v-else
        :id="rootFolder"
        :is-root="true"
        :load-on-expand="true"
        :filter-type="'application/vnd.google-apps.folder'"
        @selected-file="connectFolder"
      />
    </div>
  </section>
</template>

<script>
import FileList from "@/components/FileList.vue";
import GoogleDriveButton from "@/components/GoogleDriveButton.vue";
import { googleClient } from "@/common/google/client";
import googleDrive from "@/common/google/drive";
import { organizations } from "@/common/pipedrive/resources";
import pipedrive from "@/common/pipedrive";

export default {
  components: {
    FileList,
    GoogleDriveButton,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      company: {},
      folderId: null,
      filterText: "",
      expand: false,
      rootFolder: "0ANUrTy_w-jIvUk9PVA",
      selectedFileURL: "",
    };
  },
  computed: {
    googleDriveUrl() {
      return `https://drive.google.com/drive/folders/${this.folderId}`;
    },
    isConnected() {
      return this.folderId;
    },
  },
  mounted() {
    this.fetchCompany();
  },
  methods: {
    reloadFiles() {
      var component = this.$refs.companyFileList;
      if (component) component.fetchFiles(this.folderId || this.rootFolder);
    },
    fetchCompany() {
      if (!this.id || this.id == 0) return;

      organizations
        .byId(this.id)
        .then((response) => {
          this.company = response;
          this.folderId = this.company["fe4bc918a3bc8bedd077f9896ffd23781317d936"];
        })
        .catch(() => this.$toasted.error("Unable to fetch organization details"));
    },
    disconnectFolder() {
      pipedrive.api
        .put("organizations/" + this.id, { fe4bc918a3bc8bedd077f9896ffd23781317d936: null })
        .then((response) => {
          console.log(response.data.data);
          this.folderId = null;
          this.$toasted.success("Disconnected from Google Drive");
        })
        .catch((error) => {
          this.$toasted.error("Unable to disconnect Google Drive folder");
          console.log(error);
        });
    },
    openFile(fileId, _mimeType, webViewLink) {
      console.log("Open:" + fileId);
      window.open(webViewLink, "_blank");
    },
    async showFile(fileId) {
      console.log("Show:" + fileId);

      const client = await googleClient();
      if (!client) return; // google not available

      return googleDrive
        .getFile(client, fileId, "webViewLink")
        .then((response) => (this.selectedFileURL = response.result.webViewLink))
        .catch((error) => console.error(error));
    },
    connectFolder(folderId) {
      pipedrive.api
        .put("organizations/" + this.id, { fe4bc918a3bc8bedd077f9896ffd23781317d936: folderId })
        .then((response) => {
          console.log(response.data.data);
          this.folderId = folderId;
          this.$toasted.success("Connected to Google Drive");
        })
        .catch((error) => {
          this.$toasted.error("Unable to connect Google Drive folder");
          console.log(error);
        });
    },
  },
};
</script>

<style lang="sass" scoped></style>
