// general utility functions specific to pipedrive

export const sanitizeHTML = function (html, removeEmptyElements = false, maxLinkLength = -1) {
  // Set up DOM
  var parent = document.createElement("div");
  var children = [];
  parent.innerHTML = html;
  children = parent.getElementsByTagName("*");

  // Loop through tags in reverse order so that we can remove elements correctly
  for (let i = children.length - 1; i >= 0; i--) {
    let child = children[i];
    child.removeAttribute("style");
    child.removeAttribute("class");
    child.removeAttribute("id");

    // Trim links to max length if specified
    if (maxLinkLength > 0 && child.tagName === "A") {
      let innerText = child.innerText?.trim() || "";
      if (innerText.startsWith("http") && innerText.length > maxLinkLength) {
        child.innerText = "(link)";
      }
    }

    // First remove obviously empty elements
    if (removeEmptyElements) {
      // If the element is empty (and not a break tag), remove it
      if (!["BR", "HR"].includes(child.tagName) && child.innerText?.trim().length == 0) {
        child.remove();
      }
    }
  }

  // Second pass to remove redundant elements
  if (removeEmptyElements) {
    // If the element is a div with a single element child, promote the child to the parent
    let divs = parent.getElementsByTagName("DIV");
    for (let i = divs.length - 1; i >= 0; i--) {
      let child = divs[i];
      if (
        child.childNodes.length === 1 &&
        child.childNodes[0].nodeType === Node.ELEMENT_NODE &&
        child.parentNode !== null
      ) {
        child.insertAdjacentElement("beforebegin", child.childNodes[0]);
        child.remove();
      }
    }

    // If the element is a li with a single p, strip the p
    // Pipedrive doesn't like <li><p>...</p></li>
    let items = parent.getElementsByTagName("LI");
    for (let i = items.length - 1; i >= 0; i--) {
      let child = items[i];
      if (
        child.tagName === "LI" &&
        child.childNodes.length === 1 &&
        child.childNodes[0].nodeType === Node.ELEMENT_NODE &&
        child.childNodes[0].tagName === "P"
      ) {
        let p = child.childNodes[0];
        child.childNodes[0].remove();
        child.innerHTML = p.innerHTML;
      }
    }

    return parent.innerHTML.replace(/&nbsp;|&amp;nbsp;/g, " ");
  }
};

export default {
  sanitizeHTML,
};
