import api from "./api";

// pipedrive filter API allows a max of 16 conditions, lower by one to be conservative
const MAX_CONDITIONS = 15;

class InvalidFilterError extends Error {
  constructor(context, message) {
    super(message);
    this.context = context;
    this.name = "InvalidFilterError";
  }
}

// create a new filter to query across a set of emails.
// currently only supports querying people
const createEmailFilter = (emails) => {
  const data = {
    name: _randomName(),
    type: "people",
    conditions: _buildConditionsForEmails(emails),
  };
  return _createFilter(data);
};

// create a new filter to easily query a resource type across a set of ids
// types: org, people, deals
const createIdFilter = (type, ids) => {
  // pipedrive is internally inconsistent when creating an organization filter
  const data = {
    name: _randomName(),
    type,
    conditions: _buildConditionsForIds(type, ids),
  };
  return _createFilter(data);
};

const destroy = (filterId) => {
  // console.log("destroyFilter", filterId);
  return api.delete("filters/" + filterId);
};

// pipedrive's key for id varies by resource
const idFieldIds = {
  organization: 4003,
  person: 9042,
};

const _buildConditionsForEmails = (emails) => {
  // https://pipedrive.readme.io/docs/adding-a-filter
  let conditions = [];
  const object = "person";

  emails = emails.filter((email) => email); // remove falsy values

  emails.forEach((email) => {
    conditions.push({
      object,
      field_id: 9020, // email field_id for person resource
      operator: "=",
      value: email,
    });
  });

  return {
    glue: "and",
    conditions: [
      {
        glue: "or",
        conditions,
      },
    ],
  };
};

const _buildConditionsForIds = (type, ids) => {
  // https://pipedrive.readme.io/docs/adding-a-filter
  let conditions = [];
  let object;

  ids = ids.filter((id) => id); // remove falsy values

  if (type === "org") {
    object = "organization";
  } else if (type === "people") {
    object = "person";
  }

  ids.forEach((id) => {
    conditions.push({
      object,
      field_id: idFieldIds[object],
      operator: "=",
      value: id,
    });
  });

  return {
    glue: "and",
    conditions: [
      {
        glue: "or",
        conditions,
      },
    ],
  };
};

const _createFilter = (data) => {
  let conditionCount = 0;
  data.conditions.conditions.forEach((c) => (conditionCount += c.conditions.length));
  if (conditionCount > MAX_CONDITIONS) {
    throw new InvalidFilterError(
      data,
      `Filter cannot be created with ${conditionCount} conditions (${MAX_CONDITIONS} max)`
    );
  }
  // console.log(`Created filter with ${conditionCount} conditions`);
  return api
    .post("filters", data)
    .then((result) => result.data.data.id)
    .catch((err) => console.log("filter creation failed", err));
};

const _randomName = () => {
  return "auger_" + Math.random().toString(36).substring(2);
};

export default {
  MAX_CONDITIONS,
  createEmailFilter,
  createIdFilter,
  destroy,
};
