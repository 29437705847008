<template>
  <AutocompleteInput
    :debounce="200"
    :initial-value="initialValue"
    :placeholder="placeholder"
    :results="resultOptions"
    :size="size"
    @search="findResults"
    @select="processSelection"
  />
</template>

<script>
import AutocompleteInput from "@/components/AutocompleteInput.vue";
import { deals, organizations, people } from "@/common/pipedrive/resources";

const resourceTypes = ["deal", "organization", "person"]; // what do we support?

export default {
  components: {
    AutocompleteInput,
  },
  props: {
    resource: {
      type: String,
      required: true,
      validator: (value) => resourceTypes.indexOf(value) !== -1,
    },
    // when editing provide the textual representation of the existing value
    // via this property
    initialValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    // alter site of input, can be: small, normal, medium, large
    // see examples: https://bulma.io/documentation/form/input/#sizes
    size: {
      type: String,
      default: "normal",
    },
  },
  emits: ["selected"],
  data() {
    return {
      resultSet: [],
    };
  },
  computed: {
    resultOptions() {
      const nameProp = this.resource == "deal" ? "title" : "name";
      return this.resultSet.map((res) => [res.id, res[nameProp]]);
    },
  },
  methods: {
    findResults(input) {
      if (input?.length < 2) {
        this.resultSet = [];
      } else {
        this._fetchResults(input).then((result) => (this.resultSet = result));
      }
    },
    processSelection(selection) {
      const result = this.resultSet.find((r) => r.id == selection[0]);
      this.$emit("selected", result);
    },
    _fetchResults(input) {
      if (this.resource == "deal") {
        return deals.byName(input, { limit: 8 });
      } else if (this.resource == "organization") {
        return organizations.byName(input, { limit: 8 });
      } else if (this.resource == "person") {
        return people.byName(input, { limit: 8 });
      } else {
        console.error("Unknown pipedrive resource type");
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.autocomplete-result-list
  position: absolute
  width: 300px
  padding: 0
  margin: 0
  border: 1px solid #eee
  max-height: 296px
  overflow-y: auto
  background: #fff
.autocomplete-result
  padding: 4px 6px
  cursor: pointer
  &[aria-selected=true]
    background: #d0eafb
</style>
