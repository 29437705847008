<template>
  <div class="event-db">
    <section class="table-container section">
      <h1 class="is-size-2">
        Financing Events
        <span class="is-size-4 has-text-grey-light">
          {{ selectedFinancingEvents.length }} of {{ financingEvents.length }}
        </span>
      </h1>

      <FinancingForm
        ref="form"
        :open="formOpen"
        :editing="editing"
        :metadata="editingMetadata"
        @close="closeForm"
      />

      <div class="level toolbar">
        <div class="level-left">
          <div class="level-item">
            <button v-show="!formOpen" class="button is-small" @click="startAdd">
              + Add New Event
            </button>
          </div>
        </div>

        <div class="level-right">
          <div class="level-item">
            <label for="showEstimates" class="label is-small">
              Show estimates:&nbsp;
              <input
                id="showEstimates"
                v-model="showEstimates"
                class="checkbox is-small"
                type="checkbox"
              />
            </label>
          </div>
          <div class="level-item">
            <div class="select is-small">
              <select v-model="stageFilter">
                <option value="">All stages</option>
                <option disabled>─────</option>
                <option v-for="(stage, index) in financing_stages" :key="index" :value="stage.id">
                  {{ stage.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="level-item">
            <div class="control has-icons-left">
              <input
                v-model="filterText"
                type="text"
                class="input is-small"
                placeholder="Firm or investor..."
              />
              <span class="icon is-small is-left">
                <font-awesome-icon icon="filter" />
              </span>
            </div>
          </div>
        </div>
      </div>

      <table class="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th>Date</th>
            <th>Company</th>
            <th>Stage</th>
            <th>Lead</th>
            <th class="has-text-right">ARR</th>
            <th class="has-text-right">Investment</th>
            <th class="has-text-right">Post-Money</th>
            <th>Notes</th>
          </tr>
        </thead>

        <!-- No results from filters -->
        <tbody v-if="selectedFinancingEvents.length == 0">
          <tr>
            <td colspan="8" class="has-text-centered is-disabled is-italic">
              Unable to find any matching financing events.
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <FinancingRow
            v-for="(event, index) in selectedFinancingEvents"
            :key="index"
            :event="event"
            :lead-name="leadName(event)"
            :partner-name="partnerName(event)"
            @edit="editEvent(event)"
          />
        </tbody>

        <tfoot v-if="statistics && selectedFinancingEvents.length">
          <tr>
            <th colspan="4" class="has-text-right">
              <p v-for="(statistic, index) in ['median', 'min', 'max']" :key="statistic">
                <span :class="{ 'has-text-weight-light': index > 0 }">
                  {{ $filters.capitalize(statistic) }}
                </span>
              </p>
            </th>
            <th
              v-for="metric in ['ARR', 'investment', 'post_money']"
              :key="metric"
              class="has-text-right"
            >
              <p v-for="(statistic, index) in ['median', 'min', 'max']" :key="statistic">
                <span :class="{ 'has-text-weight-light': index > 0 }">
                  {{ $filters.currencyShort(statistics[metric][statistic]) }}
                </span>
              </p>
            </th>
            <th></th>
          </tr>
        </tfoot>
        <tfoot v-else>
          <tr>
            <th colspan="8"></th>
          </tr>
        </tfoot>
      </table>
    </section>
  </div>
</template>

<script>
import FinancingForm from "@/components/FinancingForm.vue";
import FinancingRow from "@/components/FinancingRow.vue";
import { organizations, people } from "@/common/pipedrive/resources";
import queries from "@/common/queries";
import { removeNulls } from "@/utils/array";
import sortBy from "lodash/sortBy";

export default {
  components: {
    FinancingForm,
    FinancingRow,
  },
  data() {
    return {
      editing: null,
      financingEvents: [],
      filterText: "",
      showEstimates: true,
      formOpen: false,
      leadInvestors: [],
      partners: [],
      stageFilter: "",
    };
  },
  apollo: {
    financingEvents: {
      query: queries.fetchFinancingEvents,
      fetchPolicy: "cache-and-network",
    },
    financing_stages: {
      query: queries.financingStages,
    },
  },
  computed: {
    editingMetadata() {
      if (!this.editing) return {};
      return {
        partner_name: this.partnerName(this.editing),
        lead_name: this.leadName(this.editing),
      };
    },
    orderedFinancingEvents() {
      return sortBy(this.financingEvents, ["closed_on"]).reverse();
    },
    selectedFinancingEvents() {
      let events = this.orderedFinancingEvents;
      if (this.stageFilter) {
        events = events.filter((event) => event.financing_stage_id === this.stageFilter);
      }
      if (this.filterText) {
        const match = this.filterText.toLowerCase();
        const firms = this.leadInvestors.filter((firm) => firm.name.toLowerCase().includes(match));
        const firmIds = firms.map((firm) => firm.id);
        events = events.filter(
          (event) =>
            firmIds.includes(event.lead_investor_pipedrive_id) ||
            event.company_name?.toLowerCase().includes(match)
        );
      }
      if (!this.showEstimates) {
        events = events.filter((event) => !event.estimate);
      }
      return events;
    },
    statistics() {
      if (!this.filterText && !this.stageFilter) return null;

      return ["ARR", "investment", "post_money"].reduce((statistics, key) => {
        const values = this.selectedFinancingEvents
          .map((event) => event[key])
          .sort((a, b) => a - b)
          .filter(Boolean);
        const half = Math.floor(values.length / 2);

        statistics[key] = {
          min: values[0] || 0,
          max: values[values.length - 1] || 0,
          median: values.length % 2 ? values[half] : (values[half - 1] + values[half]) / 2.0,
        };
        return statistics;
      }, {});
    },
  },
  watch: {
    financingEvents: {
      handler(newData) {
        this.fetchFirmsAndPartners(newData);
      },
      deep: true,
    },
  },
  methods: {
    closeForm() {
      this.formOpen = false;
    },
    editEvent(event) {
      this.editing = event;
      this.formOpen = true;
      this.$nextTick(() => this.$refs.form.$el.scrollIntoView());
    },
    fetchFirmsAndPartners(events) {
      this.$toasted.info("Loading leads & partners");
      const leadIds = removeNulls([...new Set(events.map((e) => e.lead_investor_pipedrive_id))]);
      const partnerIds = removeNulls([...new Set(events.map((e) => e.partner_pipedrive_id))]);
      // fetch data from pipedrive
      organizations.byIds(leadIds, (result) => this.leadInvestors.push(...result));
      people.byIds(partnerIds, (result) => this.partners.push(...result));
    },
    leadName(event) {
      if (!event.lead_investor_pipedrive_id) return null;
      const lead = this.leadInvestors.find((i) => i.id === event.lead_investor_pipedrive_id);
      return lead && lead.name;
    },
    partnerName(event) {
      if (!event.partner_pipedrive_id) return null;
      const partner = this.partners.find((p) => p.id === event.partner_pipedrive_id);
      return partner && `${partner.first_name} ${partner.last_name}`;
    },
    startAdd() {
      this.editing = null;
      this.formOpen = true;
    },
  },
};
</script>

<style lang="sass" scoped>
h1
  margin-bottom: 10px
.table-container
  padding-bottom: 300px
</style>
