import SharedSecretService from "@/services/sharedSecretService";
import { fetchClient } from "@/common/fetchclient";

const BASE_URL = "https://person.clearbit.com/v2/";

let client;

// memoize client so we only have to configure it once
const getClient = async function () {
  if (client) return client;
  const apiKey = await SharedSecretService.get("CLEARBIT_API_KEY");
  const newClient = fetchClient({
    baseURL: BASE_URL,
    headers: {
      Authorization: "Bearer " + apiKey,
    },
  });
  client = client || newClient; // allow for race conditions
  return client;
};

export const enrich = async function (email, queryParams = {}) {
  const client = await getClient();
  const params = Object.assign({ email }, queryParams);
  return client.get("/combined/find", { params });
};

export default {
  enrich,
};
