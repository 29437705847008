// Map env vars to internal configuration

class ConfigError extends Error {
  constructor(message) {
    super(message);
    this.name = "ConfigError";
  }
}

export const api = {
  // absolute URL
  url: import.meta.env.VITE_HEAVYBIT_API,
  // hasura admin secret (dev only use)
  adminSecret: import.meta.env.VITE_HEAVYBIT_API_SECRET,
};

if (!api.url) throw new ConfigError("VITE_HEAVYBIT_API must be in environment");

export const auth = {
  // auth0 domain to connect to
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  // auth0 client ID
  clientID: import.meta.env.VITE_AUTH0_CLIENTID,
  // disable authentication protection (dev only use)
  disabled: !!import.meta.env.VITE_AUTH0_DISABLED,
};

if (!auth.disabled) {
  if (!auth.domain) throw new ConfigError("VITE_AUTH0_DOMAIN must be in environment");
  if (!auth.clientID) throw new ConfigError("VITE_AUTH0_CLIENTID must be in environment");
}

export const general = {
  // user to connect as instead of using auth0 JWT (dev only use)
  forcedUser: import.meta.env.VITE_FORCE_USER,
};

export const google = {
  // API key for public APIs, private ones use OAuth2 auth
  apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
  // Client ID for google API access
  clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
};

if (!google.apiKey) throw new ConfigError("VITE_GOOGLE_API_KEY must be in environment");
if (!google.clientId) throw new ConfigError("VITE_GOOGLE_CLIENT_ID must be in environment");

export const sentry = {
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_DEPLOY_ENVIRONMENT ?? "development",
};

export default {
  api,
  auth,
  general,
  google,
  sentry,
};
