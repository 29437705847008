<template>
  <div class="event-resource">
    <div v-if="hasCategory && hasResource" class="tags">
      <span class="tag is-link is-light">
        <span class="icon is-small">
          <font-awesome-icon :icon="resourceIcon" size="xs" />
        </span>
        <a :href="resourceUrl" target="_blank">{{ linkedResource.name }}</a>
        <button class="delete is-small" @click="resetResource" />
      </span>
    </div>
    <PipedriveAutocomplete
      v-else-if="needsResource"
      :resource="resourceType"
      size="small"
      :placeholder="resourcePlaceholder"
      @selected="chooseResource"
    >
    </PipedriveAutocomplete>
  </div>
</template>

<script>
import PipedriveAutocomplete from "@/components/PipedriveAutocomplete.vue";
import { deals, people } from "@/common/pipedrive/resources";
import { web as pipedriveWeb } from "@/common/pipedrive";

export default {
  components: {
    PipedriveAutocomplete,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  emits: ["setResource"],
  data() {
    return {
      linkedResource: {},
      categoryResources: {
        pipeline: {
          icon: "dollar-sign",
          type: "deal",
        },
        portfolio: {
          icon: "building",
          type: "organization",
        },
        networking: {
          icon: "user",
          type: "person",
        },
      },
    };
  },
  computed: {
    eventCategory() {
      return this.event?.extendedProperties?.private?.eventCategory;
    },
    hasCategory() {
      return !!this.eventCategory;
    },
    hasResource() {
      return this.linkedResource?.id > 0;
    },
    needsResource() {
      // The event needs a resource if the event's category requires one & it doesn't have one
      if (!this.hasCategory || this.hasResource) return false;
      return !!this.categoryResources[this.eventCategory];
    },
    resourceIcon() {
      return this.categoryResources[this.eventCategory]?.icon;
    },
    resourceType() {
      return this.categoryResources[this.eventCategory]?.type;
    },
    resourcePlaceholder() {
      return `Choose ${this.resourceType}...`;
    },
    resourceUrl() {
      const id = this.linkedResource?.id || 0;
      switch (this.linkedResource.type) {
        case "deal":
          return pipedriveWeb.dealUrl(id);
        case "organization":
          return pipedriveWeb.organizationUrl(id);
        case "person":
          return pipedriveWeb.personUrl(id);
        default:
          return "";
      }
    },
    attendeePipedriveIds() {
      // Compute the IDs of all non-Heavybit attendees
      const allAttendees = this.event?.attendees || [];
      const externalAttendees = allAttendees.filter(
        (a) => !a.resource && !a.email.endsWith("heavybit.com") && a.pipedriveId
      );
      return [...new Set(externalAttendees.map((a) => a.pipedriveId))];
    },
  },
  watch: {
    hasCategory: function () {
      // If the event's category is removed or changed, try to set the resource from existing metadata
      this._setResourceFromEvent();
      // If no resource is found, suggest a resource based on the event's category
      if (!this.hasResource) this._suggestResource();
    },
  },
  mounted() {
    this._setResourceFromEvent();
  },
  methods: {
    chooseResource(resource) {
      // Update the local resource to the chosen resource
      this.linkedResource = {
        type: this.resourceType,
        id: resource.id,
        name: resource.name || resource.title,
      };

      // Update the gCal event properties to include the chosen resource
      let type = this.linkedResource.type;
      let idKey = `${type}Id`;
      let nameKey = type == "deal" ? "dealTitle" : `${type}Name`; // dealTitle is used instead of dealName

      let eventProperties = {};
      eventProperties[idKey] = this.linkedResource.id;
      eventProperties[nameKey] = this.linkedResource.name;

      // console.log("chooseResource", eventProperties);
      this.$emit("setResource", eventProperties);
    },
    resetResource() {
      // Clear the local resource
      this.linkedResource = {};

      // Update the gCal event metadata to remove any resource-associated event props
      let eventProperties = {
        dealId: null,
        dealTitle: null,
        organizationId: null,
        organizationName: null,
        personId: null,
        personName: null,
        activityId: null,
      };

      // console.log("resetResource", eventProperties);
      this.$emit("setResource", eventProperties);
    },
    _suggestResource() {
      // If the event has a category, try to suggest and set a resource by looking at the non-HB attendees
      const allAttendees = this.event?.attendees || [];
      const externalAttendees = allAttendees.filter(
        (a) => !a.resource && !a.email.endsWith("heavybit.com") && a.pipedriveId
      );
      if (externalAttendees.length == 0) return;

      const personId = externalAttendees[0].pipedriveId;
      if (this.eventCategory === "pipeline") {
        this._suggestDeal(personId);
      } else if (this.eventCategory === "portfolio") {
        this._suggestOrganization(personId);
      } else if (this.eventCategory === "networking") {
        // If the event is networking, use the first external attendee as the person
        this.chooseResource({
          type: "person",
          id: personId,
          name: externalAttendees[0].displayName,
        });
      }
    },
    _suggestDeal(personId) {
      // If the event is a pipeline event, try to find an open deal with the first attendee as a participant
      // console.log("suggestDeal", personId);
      deals.forPerson(personId, { status: "open" }).then((deals) => {
        if (!deals || deals.length != 1) {
          this.$toasted.info("Deal unclear, please set manually.");
          return;
        }
        this.chooseResource({
          type: "deal",
          id: deals[0].id,
          name: deals[0].title,
        });
      });
    },
    _suggestOrganization(personId) {
      // If the event is a portfolio event, use the organization of the first attendee
      // console.log("suggestOrganization", personId);
      people.byId(personId).then((person) => {
        if (!person.org_id) {
          this.$toasted.info("Organization unclear, please set manually.");
          return;
        }
        this.chooseResource({
          type: "organization",
          id: person.org_id.value,
          name: person.org_id.name,
        });
      });
    },
    _setResourceFromEvent() {
      // If the event's properties reference a resource, set the local linked resource to that
      const resourceType = this.categoryResources[this.eventCategory]?.type;
      if (!resourceType) {
        this.linkedResource = {};
        return;
      }

      let idKey = `${resourceType}Id`;
      let nameKey = resourceType == "deal" ? "dealTitle" : `${resourceType}Name`; // dealTitle is used instead of dealName
      const eventProperties = this.event?.extendedProperties?.private || {};

      this.linkedResource = {
        type: resourceType,
        id: eventProperties[idKey],
        name: eventProperties[nameKey],
      };
    },
  },
};
</script>

<style lang="sass" scoped></style>
