// custom exception type for when requests fail
class HttpError extends Error {
  constructor(context, message) {
    super(message || context.response.statusText);
    this.response = context.response;
    this.status = context.status;
    this.body = context.body;
    this.data = context.data;
    this.name = "HttpError";
  }
}

// custom exception for invalid response errors
class InvalidResponseError extends Error {
  constructor(context, message) {
    super(message);
    this.body = context.body;
    this.name = "InvalidResponseError";
  }
}

// Wrapper around the brower's native fetch that provides nice functionality
// similar to axios. Allows creating a client with shared presets, using
// specific HTTP methods via dedicated method calls, defaults to sending
// & receiving JSON, etc.
//
// example:
//  client = fetchClient(baseURL: 'https://myfoo/v1', headers: { my_header: 'foo' })
//  response = client.get("people", { params: { limit: 20 } })
export function fetchClient(defaults = {}) {
  // if a baseUrl is specified, use it as prefix for all requests
  const baseURL = defaults.baseURL;
  const responseType = defaults.responseType || "json"; // 'json' or 'text'

  // send json content-type header unless overridden
  const defaultHeaders = {
    "Content-Type": "application/json",
    ...defaults.headers,
  };

  // build full request url, incorporating baseURL and query params
  const buildUrl = (baseUrl, url, params = undefined) => {
    let queryString = "";
    if (params) {
      queryString = "?" + new URLSearchParams(params).toString();
    }
    if (!baseUrl || url.includes("://")) return `${url}${queryString}`;
    return `${baseUrl}${url}${queryString}`;
  };

  // parse out text body and json data if available
  const parseBodyAndData = async (response) => {
    let data = undefined;
    const body = await response.text();
    if (body && responseType === "json") {
      try {
        data = JSON.parse(body);
      } catch (err) {
        throw new InvalidResponseError({ body }, `Response is not valid JSON, see body`);
      }
    }
    return { body, data };
  };

  // base method for requests with arguments similar to fetch
  const request = (url, options = {}) => {
    const params = options.params;
    const fullUrl = buildUrl(baseURL, url, params);
    const headers = { ...defaultHeaders, ...options.headers };
    const requestOptions = { ...options, headers };

    return fetch(fullUrl, requestOptions).then(async (response) => {
      const status = response.status;
      const ok = response.ok;
      const { body, data } = await parseBodyAndData(response);
      const context = { response, body, data, status, ok };
      if (ok) {
        return context;
      } else {
        throw new HttpError(context, `Request failed with status code ${status}`);
      }
    });
  };

  // helper methods for making use of a given HTTP method easier

  const get = (url, options = {}) => {
    const opts = { ...options, method: "GET" };
    return request(url, opts);
  };

  const post = (url, data = undefined, options = {}) => {
    const opts = { ...options, method: "POST" };
    if (data !== undefined) {
      const jsonBody = JSON.stringify(data);
      opts.body = jsonBody;
    }
    return request(url, opts);
  };

  const put = (url, data = undefined, options = {}) => {
    const opts = { ...options, method: "PUT" };
    if (data !== undefined) {
      const jsonBody = JSON.stringify(data);
      opts.body = jsonBody;
    }
    return request(url, opts);
  };

  // delete is a reserved word
  const deleteRequest = (url, options = {}) => {
    const opts = { ...options, method: "DELETE" };
    return request(url, opts);
  };

  return {
    request,
    get,
    post,
    put,
    delete: deleteRequest,
  };
}
