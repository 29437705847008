<template>
  <section class="fund-pipeline section">
    <div class="toolbar">
      <h2 class="title">Pipeline</h2>
      <div class="tools"></div>
    </div>
    <br />

    <StatsBar :stats="stats" />
    <hr />

    <div class="columns is-gapless">
      <div v-for="stage in stages.slice(2, 6)" :key="stage.id" class="column">
        <h3 class="is-size-5">{{ stage.name }}</h3>
        <p class="has-text-grey-light">
          {{ openDeals(stage).length }} deals, ${{ unweightedValue(stage) }}m
        </p>
        <!-- Iterate over the deals in each stage -->

        <div v-for="deal in openDeals(stage)" :key="deal.id" class="is-flex is-flex-direction-row">
          <p class="is-size-7">
            {{ deal.org_id?.name || deal.person_id?.name }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import StatsBar from "@/components/StatsBar.vue";

import { deals, stages } from "@/common/pipedrive/resources";
import { sumProperty } from "@/utils/number";

export default {
  components: {
    StatsBar,
  },
  props: {
    pipedriveId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      stages: [],
    };
  },
  computed: {
    totalCoverage() {
      // Total coverage is the sum of all open deals in the first 4 stages
      const earlyDeals = this.stages.slice(0, 6).flatMap((s) => s.deals);
      const deals = earlyDeals.filter((d) => d.status === "open");
      return (sumProperty(deals, "value") / 1e6).toFixed(0);
    },
    totalCommitted() {
      // Total committed is the sum of all closed deals and open deals in the last 2 stages
      const lateDeals = this.stages.slice(5).flatMap((s) => s.deals);
      const committedDeals = lateDeals.filter((d) => d.status === "open" || d.status === "won");
      return (sumProperty(committedDeals, "value") / 1e6).toFixed(0);
    },
    totalClosed() {
      // Total closed is the sum of all closed deals
      const allDeals = this.stages.flatMap((s) => s.deals);
      const wonDeals = allDeals.filter((d) => d.status === "won");
      return (sumProperty(wonDeals, "value") / 1e6).toFixed(0);
    },
    stats() {
      return [
        { title: "Pipeline Coverage", value: `$${this.totalCoverage}m` },
        { title: "Total Committed", value: `$${this.totalCommitted}m` },
        { title: "Total Closed", value: `$${this.totalClosed}m` },
      ];
    },
  },
  watch: {
    pipedriveId() {
      this.fetchStages();
    },
  },
  created() {
    this.fetchStages();
  },
  methods: {
    openDeals(stage) {
      return stage.deals.filter((d) => d.status === "open");
    },
    unweightedValue(stage) {
      // Unweighted value is the sum of all open deals in a stage
      const sum = (tot, deal) => (tot = tot + deal.value);
      return (this.openDeals(stage).reduce(sum, 0) / 1e6).toFixed(1);
    },
    fetchStages() {
      // Retrieve details about the stages in the LP pipeline
      stages
        .byPipeline(17)
        .then((stages) => {
          // Add an empty deals array to each stage
          this.stages = stages.map((stage) => ({ ...stage, deals: [] }));
          // Fetch deals for each stage
          this.stages.forEach((stage) => this.fetchDealsForStage(stage.id));
        })
        .catch((err) => {
          this.$toasted.error("Unable to find LP pipeline stages");
          console.log(err);
        });
    },
    fetchDealsForStage(stageId) {
      deals
        .byStage(stageId)
        .then((deals) => {
          // Only add deals for the selected fund (if one is selected otherwise use all)
          const fundKey = "c9ee69a1f5d4ef7f1f842834a80ce4cd91328ac2";
          const fundId = this.pipedriveId;
          const fundDeals = fundId > 0 ? deals.filter((d) => d[fundKey]?.value == fundId) : deals;

          // Find the stage in the list of stages and add the deals
          const stage = this.stages.find((s) => s.id === stageId);
          stage.deals = fundDeals;
        })
        .catch((err) => {
          this.$toasted.error("Unable to find LP pipeline stages");
          console.log(err);
        });
    },
  },
};
</script>

<style lang="sass"></style>
