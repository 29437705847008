import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { createApolloProvider } from "@vue/apollo-option";
import { bearerHeader } from "@/common/authorization";
import config from "@/common/config";

let headers = {};
// ignore authentication and force a specific user
if (config.auth.disabled && config.api.adminSecret && config.general.forcedUser) {
  headers["x-hasura-admin-secret"] = config.api.adminSecret;
  headers["x-hasura-user-id"] = config.general.forcedUser;
  headers["x-hasura-role"] = "user";
}

const httpLink = createHttpLink({
  uri: config.api.url, // must be absolute URL
});

const cache = new InMemoryCache();

// send JWT authorization we get from Auth0 to Hasura
const authorizedLink = setContext((_, { headers }) => {
  return { headers: { ...headers, authorization: bearerHeader() } };
});

export const apolloClient = new ApolloClient({
  link: authorizedLink.concat(httpLink),
  cache: cache,
  connectToDevTools: import.meta.env.NODE_ENV === "development",
});

export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});
