<template>
  <section class="section overview">
    <router-link :to="{ name: 'overview' }" class="switch">Switch to Portfolio</router-link>

    <div class="program-overview">
      <h1 class="is-size-2">Program Overview</h1>
      <div class="level is-align-items-flex-end">
        <!-- Statistics -->
        <div class="level-left">
          <StatsBar :stats="programStats" />
        </div>

        <!-- Week picker -->
        <div class="level-item week-picker">
          <h2>{{ dateFormat(startDate, "LLL d y") }} – {{ dateFormat(endDate, "LLL d y") }}</h2>
          <div class="controls">
            <a class="button is-small" @click="shiftWeeks(-1)">⇦ Prev Week</a>
            <a class="button is-small" @click="loadCurrentWeek()">This week</a>
            <a class="button is-small" @click="shiftWeeks(1)">Next Week ⇨</a>
          </div>
        </div>

        <!-- Filters -->
        <div class="level-right program-activity-filters">
          <div class="level-item is-flex is-flex-direction-column is-align-items-flex-end">
            <div class="is-flex">
              <label for="showAllActivities" class="label is-small is-clickable">
                Show all activities
                <input
                  id="showAllActivities"
                  v-model="showAllActivities"
                  class="checkbox is-small"
                  type="checkbox"
                />
              </label>
              <label for="showAdditionalNotes" class="label is-small is-clickable">
                Show additional notes
                <input
                  id="showAdditionalNotes"
                  v-model="showAdditionalNotes"
                  class="checkbox is-small"
                  type="checkbox"
                />
              </label>
            </div>
            <ProgramPartners small @select="selectPerson" />
          </div>
        </div>
      </div>
      <br />

      <div class="columns">
        <div class="column is-two-fifths" style="border-right: 1px solid #eaeaea">
          <ProgramActivities
            class="program-activities"
            :activities="calendarActivities"
            :selected-person-id="(selectedPerson || {}).id"
            @select="selectActivity"
            @update="updateActivity"
          />
        </div>

        <div class="column" style="padding-left: 1rem">
          <ProgramNotes
            :notes="filteredNotes"
            :selected-activity="selectedActivity"
            @update="updateActivity"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProgramActivities from "@/components/ProgramActivities.vue";
import ProgramNotes from "@/components/ProgramNotes.vue";
import ProgramPartners from "@/components/ProgramPartners.vue";
import StatsBar from "@/components/StatsBar.vue";

import pipedrive from "@/common/pipedrive";
import { organizations } from "@/common/pipedrive/resources";
import { dateFormat } from "@/common/filters";
import { PARTNERS as partnerContacts } from "@/common/partners";

import { personPictureUrl as avatarURL } from "@/common/people";
import { toDate, toDateString } from "@/utils/date";
import { stripTags } from "@/utils/html";
import { addWeeks, startOfWeek, endOfWeek } from "date-fns";

const PROGRAM_ACTIVITY_TYPES = [
  "partner_meeting",
  "board_meeting",
  "introduction",
  "additional_notes",
  "staff_office_hours",
];

export default {
  components: {
    ProgramActivities,
    ProgramNotes,
    ProgramPartners,
    StatsBar,
  },
  props: {
    start: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      selectedActivity: null,
      selectedPerson: null,
      pipedriveActivities: [],
      pipedriveNotes: [],
      pipedrivePeople: {}, // A map of Pipedrive people by ID
      portfolioCompanies: [],
      allActivities: [],
      startDate: new Date(),
      endDate: new Date(),
      showAllActivities: false,
      showAdditionalNotes: true,
    };
  },
  computed: {
    calendarActivities() {
      return this._excludeAdditionalNotes(this.filteredActivities);
    },
    filteredActivities() {
      // Either show all notes or only those assigned to portfolio companies
      const activities = this.showAllActivities ? this.allActivities : this.programActivities;

      // If no-one is selected, show everyone's notes & events
      if (!this.selectedPerson?.id) return activities;

      // Otherwise show note & event activities with participants that include, or were created by the selected person
      const participantId = parseInt(this.selectedPerson?.id);
      return activities.filter((a) => {
        const ids = (a.participants || []).map((p) => p.id);
        return ids.includes(participantId) || a.user?.id == participantId;
      });
    },
    filteredNotes() {
      const activities = this.filteredActivities;
      return this.showAdditionalNotes ? activities : this._excludeAdditionalNotes(activities);
    },
    portfolioActivities() {
      // Return notes & activities assigned to portfolio companies
      let portfolioIds = this.portfolioCompanies.map((c) => c.id);
      return this.allActivities.filter((a) => portfolioIds.includes(a.org_id));
    },
    programActivities() {
      return this.portfolioActivities.filter((a) => PROGRAM_ACTIVITY_TYPES.includes(a.type));
    },
    programStats() {
      const excludeNotes = this._excludeAdditionalNotes;
      const total = excludeNotes(this.allActivities).length;
      const program = excludeNotes(this.filteredActivities).length;
      const hasContent = (a) => stripTags(a.content).length > 0;
      const completed = this.filteredNotes.filter(hasContent).length;
      return [
        { title: "Program<br/>Activities", value: program },
        { title: "Completed<br/>Notes", value: completed },
        { title: "Total<br/>Activities", value: total },
      ];
    },
  },
  mounted() {
    this._fetchPortfolio();
    if (this.start) {
      this.setWeek(this.start);
    } else {
      this.loadCurrentWeek();
    }
  },
  methods: {
    dateFormat,
    loadCurrentWeek() {
      this.setWeek(new Date());
    },
    selectActivity(activity) {
      this.selectedActivity = activity;
    },
    selectPerson(person) {
      this.selectedPerson = person;
    },
    // Shift dates by N weeks and fetch calendar data for that week
    shiftWeeks(weeks) {
      this.selectedActivity = null;
      this.setWeek(addWeeks(this.startDate, weeks));
    },
    updateActivity(activity) {
      if (activity.type == "additional_notes") {
        this._updatePipedriveNote(activity);
      } else {
        this._updatePipedriveActivity(activity);
      }
    },
    setWeek(startDate) {
      const start = toDate(startDate);
      this.startDate = startOfWeek(start, { weekStartsOn: 1 });
      this.endDate = endOfWeek(start, { weekStartsOn: 1 });
      this._updateQueryParams();
      this._fetchActivities();
    },
    _excludeAdditionalNotes(activities) {
      return (activities || []).filter((a) => a.type != "additional_notes");
    },
    async _fetchActivities() {
      // Fetch activities from the current week
      const params = {
        start_date: this.dateFormat(this.startDate, "yyyy-MM-dd"),
        end_date: this.dateFormat(this.endDate, "yyyy-MM-dd"),
        user_id: 0,
        limit: 1000,
      };

      // Fetch both activities (that could contain notes) and actual notes from pipedrive
      let activityResponse = await pipedrive.api.get("activities", params);
      let notesResponse = await pipedrive.api.get("notes", params);

      let activities = activityResponse.data.data || [];
      let notes = notesResponse.data.data || [];

      // Create a list of all referenced _user_ ids & convert them to pipedrive _people_ ids
      const userIds = [
        ...new Set([
          ...notes.map((n) => n.user_id), // user ids from notes
          ...activities.map((a) => a.assigned_to_user_id), /// user ids from activities
        ]),
      ]
        .map((id) => partnerContacts[id])
        .filter((id) => id != null);

      // Create a list of all referenced participant ids (already pipedrive people ids)
      const participantIds = activities.flatMap((a) =>
        a.participants?.map((p) => `${p.person_id}`)
      );

      // Combine the user and participant ids and remove duplicates
      const peopleIds = [...new Set([...participantIds, ...userIds])];

      // Update the local cache of pipedrive activities and notes
      this.pipedriveActivities = activities;
      this.pipedriveNotes = notes;

      // If there are new ids, fetch the profiles for the new ids
      const newIds = peopleIds.filter((id) => !this.pipedrivePeople[id]);
      if (newIds.length > 0) {
        this.$toasted.info(`Fetching ${newIds.length} ${newIds.length == 1 ? "person" : "people"}`);

        // Create an array of Promises that fetch the people profiles
        const promises = [];
        const maxFilters = 9;
        for (let i = 0; i < newIds.length; i += maxFilters) {
          const subarray = newIds.slice(i, i + maxFilters);
          promises.push(pipedrive.resources.people.byIds(subarray));
        }

        // Call all the promises, flatten the results into a single array
        // filter out any null values and update the local cache
        const results = await Promise.all(promises);
        const people = (results || []).flat().filter((p) => p);
        people.forEach((person) => (this.pipedrivePeople[person.id] = person));
      }

      // Recalculate the combined list of activities with the new people
      this.allActivities = this._formatActivities();
    },
    _fetchPortfolio() {
      // Fetch companies from all funds and filter with computed property
      organizations
        .byFilter(278, { sort: "name ASC" })
        .then((response) => (this.portfolioCompanies = response))
        .catch(() => this.$toasted.error(`Unable to fetch portfolio companies`));
    },
    _formatActivities() {
      let activities = [];

      // Create activity objects from pipedrive activities
      this.pipedriveActivities.forEach((a) => {
        // Only certain pipeline and portfolio activities should be shown
        // if (!this.activityTypes.includes(a.type)) return;
        // Enrich each activity with the associated pipedrive people
        activities.push({
          id: a.id,
          type: a.type,
          content: a.note,
          done: a.done,
          org_id: a.org_id,
          org_name: a.org_name,
          subject: a.subject,
          start: `${a.due_date} ${a.due_time || "00:00"}:00`, // Activity due_time is the equivalent to the note add_time
          user: this._formatUser(partnerContacts[a.assigned_to_user_id]),
          participants: (a.participants || []).map((p) => this._formatUser(p.person_id)),
        });
      });

      // Combine the pipedrive notes with the pipedrive activities
      this.pipedriveNotes.forEach((n) => {
        activities.push({
          id: n.id,
          type: "additional_notes",
          content: n.content,
          done: true,
          org_id: n.org_id,
          org_name: n.organization?.name,
          subject: "Additional Notes",
          start: n.add_time,
          user: this._formatUser(partnerContacts[n.user_id]),
          participants: [],
        });
      });

      // Return the activities in reverse order so they are in chronological order
      return activities.sort((a, b) => toDate(b.start) - toDate(a.start));
    },
    _formatUser(id) {
      let person = this.pipedrivePeople[id];
      return {
        id,
        name: person?.name || "Unknown id: " + id,
        icon_url: avatarURL(person),
      };
    },
    _updatePipedriveActivity(activity) {
      // Create a properly formatted pipedrive activity by finding the original activity
      // and overwriting the fields that have changed
      const index = this.pipedriveActivities.findIndex((a) => a.id === activity.id);
      const oldActivity = this.pipedriveActivities[index];
      const newActivity = {
        ...oldActivity,
        note: activity.content,
        type: activity.type,
      };

      // Update the activity in the pipedrive api
      pipedrive.api
        .put("activities/" + activity.id, newActivity, {})
        .then((response) => {
          const activity = response.data.data;
          this.pipedriveActivities[index] = activity;
          this.$toasted.success(`Updated notes for '${activity.subject}'`);
        })
        .catch((err) => console.log(err));
    },
    _updatePipedriveNote(note) {
      // Create a properly formatted pipedrive note by finding the original note
      // and overwriting the fields that have changed
      const index = this.pipedriveNotes.findIndex((n) => n.id === note.id);
      const oldNote = this.pipedriveNotes[index];
      const newNote = {
        ...oldNote,
        content: note.content,
      };

      // Update the note in the pipedrive api
      pipedrive.api
        .put("notes/" + note.id, newNote, {})
        .then((response) => {
          const note = response.data.data;
          this.pipedriveNotes[index] = note;
          // console.log("Updated note:", note);
        })
        .catch((err) => console.log(err));
    },
    _updateQueryParams() {
      const startString = toDateString(this.startDate);
      const query = this.$route.query;
      // don't navigate if we're already there
      if (query.start == startString) return;
      this.$router.replace({ query: { start: startString } });
    },
  },
};
</script>

<style lang="sass" scoped>
section.overview
  .switch
    float: right
    font-size: 0.8rem
    color: lightgray
    margin-top: 1.2rem
    &:hover
      color: darkgray
.week-picker
  display: flex
  flex-direction: column
  h2
    margin-bottom: 0.25rem
    font-size: 1.2rem
  .controls
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    *
      margin-right: 10px
.program-activity-filters
  label
    padding-left: 1rem
    padding-right: 0.2rem
    margin: 0.5rem 0
    input[type=checkbox]
      top: 0.15rem
      margin-left: 2px
.program-activities
  position: sticky
  top: 0
  max-height: 100vh
  overflow-y: auto
</style>
