<template>
  <div>
    <div v-show="open" class="form has-divider">
      <div class="columns">
        <div class="column is-one-quarter">
          <div class="field">
            <label for="" class="label">Date</label>
            <div class="control">
              <MonthPicker v-model="monthPickerDate" @update="updateClosedOn" />
            </div>
          </div>
        </div>
        <div class="column is-half">
          <div class="field">
            <label for="" class="label">Company</label>
            <div class="control">
              <PipedriveAutocomplete
                resource="organization"
                :initial-value="companyName"
                @selected="setCompany"
              />
            </div>
          </div>
        </div>
        <div class="column is-one-quarter">
          <div class="field">
            <label for="" class="label">Stage</label>
            <div class="control">
              <div class="select">
                <select v-model="event.financing_stage_id">
                  <option disabled>Please select</option>
                  <option v-for="(stage, index) in financing_stages" :key="index" :value="stage.id">
                    {{ stage.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div class="field">
            <label for="" class="label">
              Investment
              <span class="tip">{{ currencyShort(event.investment, "") }}</span>
            </label>
            <div class="control">
              <input v-model="event.investment" class="input" type="text" />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label for="" class="label"
              >Post-Money
              <span class="tip">{{ currencyShort(event.post_money, "") }}</span>
            </label>
            <div class="control">
              <input v-model="event.post_money" class="input" type="text" />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label for="" class="label"
              >ARR
              <span class="tip">{{ currencyShort(event.ARR, "") }}</span>
            </label>
            <div class="control">
              <input v-model="event.ARR" class="input" type="text" />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label for="" class="label">Type</label>
            <div class="control">
              <div class="select">
                <select v-model="event.financing_type_id">
                  <option disabled>Please select</option>
                  <option v-for="(type, index) in financing_types" :key="index" :value="type.id">
                    {{ type.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div class="field">
            <label for="" class="label">Lead Investor</label>
            <div class="control">
              <PipedriveAutocomplete
                resource="organization"
                :initial-value="leadName"
                @selected="setLeadInvestor"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label for="" class="label">Partner</label>
            <div class="control">
              <PipedriveAutocomplete
                resource="person"
                :initial-value="partnerName"
                @selected="setPartner"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="field">
        <label for="" class="label">Notes</label>
        <div class="control">
          <textarea v-model="event.notes" class="textarea" />
        </div>
      </div>

      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input v-model="event.estimate" type="checkbox" />
            Estimate or incomplete information
          </label>
        </div>
      </div>

      <div v-if="!editing" class="field is-grouped">
        <div class="control">
          <button class="button" @click.prevent="createEvent">Add Event</button>
        </div>
        <div class="control">
          <button class="button is-text" @click.prevent="closeForm">Cancel</button>
        </div>
      </div>

      <div v-else class="space-between">
        <div class="field is-grouped">
          <div class="control">
            <button class="button" @click.prevent="updateEvent">Update Entry</button>
          </div>
          <div class="control">
            <button class="button is-text" @click.prevent="closeForm">Cancel</button>
          </div>
        </div>
        <div class="field">
          <div class="control">
            <button class="button" @click.prevent="deleteEvent">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MonthPicker from "@/components/MonthPicker.vue";
import PipedriveAutocomplete from "@/components/PipedriveAutocomplete.vue";
import { currencyShort } from "@/common/filters";
import mutations from "@/common/mutations";
import queries from "@/common/queries";
import { toNumberOrNull } from "@/utils/number";
import pick from "lodash/pick";
import clone from "lodash/clone";

export default {
  components: {
    MonthPicker,
    PipedriveAutocomplete,
  },
  props: {
    editing: {
      type: Object,
      default: null,
    },
    open: {
      type: Boolean,
      default: false,
    },
    metadata: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["close"],
  data() {
    return {
      event: {
        financing_stage_id: 2,
        financing_type_id: 1,
      },
      monthPickerDate: new Date(),
    };
  },
  apollo: {
    financing_stages: {
      query: queries.financingStages,
    },
    financing_types: {
      query: queries.financingTypes,
    },
  },
  computed: {
    companyName() {
      return this.editing && this.editing.company_name;
    },
    leadName() {
      return this.metadata.lead_name;
    },
    partnerName() {
      return this.metadata.partner_name;
    },
    propsForUpdate() {
      const props = pick(this.event, [
        "ARR",
        "closed_on",
        "company_name",
        "company_pipedrive_id",
        "estimate",
        "financing_stage_id",
        "financing_type_id",
        "investment",
        "lead_investor_pipedrive_id",
        "notes",
        "partner_pipedrive_id",
        "post_money",
      ]);
      return this._cleanNumerics(props);
    },
  },
  watch: {
    editing(newData) {
      if (newData) {
        this.event = clone(newData);
        this.monthPickerDate = this.event.closed_on;
      } else {
        this._clearState();
      }
    },
  },
  methods: {
    closeForm() {
      this.$emit("close");
    },
    createEvent() {
      this.$apollo
        .mutate({
          mutation: mutations.createFinancingEvent,
          variables: {
            objects: [this._cleanNumerics(this.event)],
          },
          // add returned item to existing apollo cache; insert_financing_events is the
          // key for data we care about in the response payload
          update: (store, { data: { insert_financing_events } }) => {
            const query = queries.fetchFinancingEvents;
            let data = store.readQuery({ query });
            const financingEvents = [...data.financingEvents, insert_financing_events.returning[0]];
            store.writeQuery({ query, data: { ...data, financingEvents } });
            this.closeForm();
          },
        })
        .catch((error) => {
          console.error(error);
          this.$toasted.error("Updated failed.");
        });
    },
    currencyShort,
    deleteEvent() {
      if (!window.confirm("Are you sure you want to delete this event?")) return;
      const removeId = this.event.id;
      this.$apollo
        .mutate({
          mutation: mutations.deleteFinancingEvent,
          variables: {
            id: removeId,
          },
          // remove from local cache
          update: (cache) => {
            const identifier = cache.identify({ __typename: "financing_events", id: removeId });
            cache.evict({ id: identifier });
            cache.gc();
            this.closeForm();
          },
        })
        .catch((error) => {
          console.error(error);
          this.$toasted.error("Updated failed.");
        });
    },
    setCompany(company) {
      console.log("setCompany", company);
      if (company) {
        this.event.company_pipedrive_id = company.id;
        this.event.company_name = company.name;
      } else {
        this.event.company_pipedrive_id = null;
        this.event.company_name = null;
      }
    },
    setLeadInvestor(lead) {
      console.log("setLeadInvestor", lead);
      this.event.lead_investor_pipedrive_id = lead ? lead.id : null;
    },
    setPartner(partner) {
      console.log("setPartner", partner);
      this.event.partner_pipedrive_id = partner ? partner.id : null;
    },
    updateClosedOn(value) {
      this.event.closed_on = value;
    },
    updateEvent() {
      this.$apollo
        .mutate({
          mutation: mutations.updateFinancingEvent,
          variables: {
            id: Number(this.event.id),
            changes: this.propsForUpdate,
          },
        })
        .then(() => this.closeForm())
        .catch((error) => {
          console.error(error);
          this.$toasted.error("Updated failed.");
        });
    },
    // ensure numeric properties are numeric before sending to Hasura
    _cleanNumerics(event) {
      let returnable = Object.assign({}, event); // clone
      const eventKeys = Object.keys(event);
      // only process keys that are present
      const keys = ["ARR", "investment", "post_money"].filter((k) => eventKeys.includes(k));
      keys.forEach((k) => (returnable[k] = toNumberOrNull(returnable[k])));
      return returnable;
    },
    _clearState() {
      this.event = {
        financing_stage_id: 2,
        financing_type_id: 1,
      };
      this.monthPickerDate = null;
    },
  },
};
</script>

<style lang="sass" scoped>
label:not(.checkbox)
  display: flex
  justify-content: space-between
  align-items: baseline
  .tip
    font-size: 0.8rem
    font-weight: 400
.add-new
  display: flex
  justify-content: flex-end
.space-between
  display: flex
  justify-content: space-between
.has-divider
  border-bottom: 1px dashed #ccc
  padding-bottom: 15px
  margin-bottom: 15px
</style>
