<template>
  <div class="theme-light">
    <TheHeader v-if="showHeaderFooter" />
    <main>
      <router-view />
    </main>
    <TheFooter v-if="showHeaderFooter" />
  </div>
</template>

<script>
import { mapState } from "pinia";
import userState from "@/services/userStateService";
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import { initGoogle } from "@/common/google/init";
import { useUserStore } from "@/stores/user";

export default {
  components: {
    TheHeader,
    TheFooter,
  },
  computed: {
    ...mapState(useUserStore, {
      showHeaderFooter: "hadAuthenticatedSession",
    }),
  },
  created() {
    userState.identify();
  },
  mounted() {
    initGoogle();
  },
};
</script>

<style lang="sass">
@use "./assets/styles/application"
@use "./common/toasted/sass/toast"
main
  min-height: 55rem
  padding-top: 0.5rem
  background: white
</style>
